/**
 *      Admin Interface - App state
 *      @author Maker Design Limited <digital@maker.co.nz>
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class AppStateService {

  // statDate: TimePeriod;

  constructor(private toastr: ToastrService) {

  }

  /*
      View's States
  */

  //Spinner
  private spinnerSource = new BehaviorSubject<boolean>(false);
  spinner = this.spinnerSource.asObservable();

  showSpinner() {
    this.spinnerSource.next(true);
  }

  hideSpinner() {
    this.spinnerSource.next(false);
  }

}