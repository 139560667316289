import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchTermFilter',
    pure: false
})
export class SearchTermFilterPipe implements PipeTransform {
    transform(items: any[], term:string): any {
        // console.log('term', term);
        return term ? items.filter(item => item.searchterm.toLowerCase().indexOf(term.toLowerCase()) !== -1) : items;
    }
}
