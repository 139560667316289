import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth-guard.service';

// Import Containers
import { FullLayoutComponent, SimpleLayoutComponent, SettingsLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { AuthService } from 'app/services';
import { StatsComponent } from 'app/views/stats/stats.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/overview',
    pathMatch: 'full',
  },
  {
    path: '',
    component: FullLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'overview',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      }
    ]
  },
  {
    path: 'reports',
    component: FullLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Reports'
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./views/stats/stats.module').then(m => m.StatsModule)
      }
    ]
  },
  {
    path: 'bannerads',
    component: FullLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Banner Advertisments'
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./views/bannerads/bannerads.module').then(m => m.BanneradsModule)
      }
    ]
  },
  {
    path: 'tools',
    component: FullLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Search Tools'
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./views/search-tools/search-tools.module').then(m => m.SearchToolsModule)
      }
    ]
  },
  {
    path: 'management',
    component: FullLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Management'
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./views/manage/manage.module').then(m => m.ManageModule)
      }
    ]
  },
  {
    path: 'settings',
    component: FullLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Settings'
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule)
      }
    ]
  },
  {
    path: 'account',
    component: SimpleLayoutComponent,
    data: {
      title: 'Account'
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule)
      }
    ]
  },
  {
    path: '**',
    component: P404Component


  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
