import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { AppDataDaterange } from './app-data-daterange/app-data-daterange.component';
// import { BsDropdownModule, BsDatepickerModule } from 'ngx-bootstrap/';
import { TimeAgoPipe } from 'app/pipes'
import { CircularCanvasComponent } from 'app/components/canvas';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        BsDropdownModule,
        BsDatepickerModule,
        ButtonsModule.forRoot()
    ],
    declarations: [
        AppDataDaterange, 
        TimeAgoPipe,
        CircularCanvasComponent
    ],
    exports: [
        CommonModule,
        AppDataDaterange,
        BsDropdownModule,
        BsDatepickerModule,
        CircularCanvasComponent,
        TimeAgoPipe,
        ButtonsModule
    ],
    providers: []
})
export class SharedModule { }
