<div class="circular" [ngStyle]="{'width.px': width, 'height.px': height}" [ngClass]="{'disabled':disabled, 'disable-hover':disableHover}">
  
  <canvas #canvas class="circular-canvas" id="canvas" [width]="width" [height]="height"></canvas>
 
  <div class="inner-text">
    <div class="text-container {{type}}">

      <div class="date" *ngIf="type === 'summary'" >{{date}}</div>

      <div class="value {{type}}" [ngStyle]="{'font-size.px': valueFontSize, 'font-weight': fontWeight}" >{{value}}</div>
      <div *ngIf="disabled" class="disabled-message">Disabled</div>
      <div class="value-percentage" *ngIf="type !== 'summary'">{{valuePercent}}<span style="font-size: 50%;">%</span> </div>

      <div class="unit {{type}}" [ngStyle]="{'width.px': width, 'font-size.px': unitFontSize}">{{unit}}</div>
      <div class="title {{type}}" [ngStyle]="{'width.px': width}">{{title}}</div>
    </div>
  </div>

</div>
