import { Component, OnInit } from '@angular/core';
import { IDateRange } from 'app/interfaces';
import { DataService, AuthService } from 'app/services';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { APP_DATE_PATTERN } from 'app/variables';


@Component({
  selector: 'date-range',
  templateUrl: 'app-data-daterange.component.html',
  styleUrls:['./app-data-daterange.component.scss']
})

export class AppDataDaterange implements OnInit {


  dateRange: IDateRange = { selected: 1, range: '', datefrom: new Date, dateto: new Date(), title: '' };
  dateRangePickerValues: Array<Date>;
  status: { isopen: boolean } = { isopen: false };
  bsConfig: Partial<BsDatepickerConfig> = Object.assign({}, { containerClass: 'theme-blue' });
  maxDate:Date = moment().add(1,'day').toDate();
  minDate:Date = moment('2016/01/01','YYYY/MM/DD').toDate();

  constructor(private dataService: DataService, private _as: AuthService) { }

  ngOnInit() {

    this.change(false);
    
    this.dataService.dataDateRange$.subscribe( data => {
      //let date = new Date();
      let today = `${moment().format('DD/MM/YYYY')}`;
      if(data.title == 'Today' && data.range != today) this.setDateRange(1);
      else this.dateRange = data;
    });

  }


  setDateRange(range: number) {
    let date = new Date();
    this.dateRange.selected = range;
    let dateOption = {day:"2-digit", month:"2-digit", year:"numeric"};
    switch (range) {
      case 0: this.dateRange.title = "Custom date";
       this.dateRange.datefrom = this.dateRangePickerValues[0];
       this.dateRange.dateto = this.dateRangePickerValues[1];
      // this.dateRange.datefrom = this.dateRangePickerValues[0].toLocaleDateString('en-GB').replace(/\//g, "-") + ' ' + this.dateRangePickerValues[0].toLocaleTimeString('en-GB');
      // this.dateRange.dateto = this.dateRangePickerValues[1].toLocaleDateString('en-GB').replace(/\//g, "-") + ' ' + this.dateRangePickerValues[1].toLocaleTimeString('en-GB');
      // this.dateRange.range = `${this.dateRangePickerValues[0].toLocaleDateString('en-GB').replace('/'+date.getFullYear(),'')} - ${this.dateRange.dateto.slice(0,-9).replace(/-/g, "/")}`;
      this.dateRange.range = this.getBeautyDateRange(this.dateRangePickerValues[0], this.dateRangePickerValues[1]);
        break;
      case 1: this.dateRange.title = 'Today';
        this.dateRange.range = `${moment().format('DD/MM/YYYY')}`;
        this.dateRange.datefrom = moment().startOf('day').toDate();
        this.dateRange.dateto = moment().endOf('day').toDate();
        break;
      case 2: this.dateRange.title = 'This Week';
        this.dateRange.range = `${this.getThisWeekRange('short')}/${date.getFullYear()}`;
        this.dateRange.datefrom = moment().startOf('isoWeek').toDate();
        this.dateRange.dateto = moment().endOf('isoWeek').toDate();
        break;
      case 3: this.dateRange.title = 'This Month';
        this.dateRange.range = `${this.getThisMonthRage('short')}/${date.getFullYear()}`;
        this.dateRange.datefrom = moment().startOf('month').toDate();
        this.dateRange.dateto = moment().endOf('month').toDate();
        break;
    }
    this.toggleDropdown();
    this.dataService.setDataDateRange(this.dateRange, this._as.getAuthUser());
  }

  getThisWeekRange(format: string): string {
    if (format === 'short') {
      return `${moment().startOf('isoWeek').format('D/M')} - ${moment().endOf('isoWeek').format('D/M')}`;
    } else {
      return `${moment().startOf('isoWeek').format('D/M/Y')} - ${moment().endOf('isoWeek').format('D/M/Y')}`;
    }

  }

  getThisMonthRage(format: string): string {
    // out put shoud be 1/xx - 31/xx
    let fdm = moment().month() + 1;
    let ldm = moment().daysInMonth();
    let year = moment().year();
    if ('short') {
      return `1/${fdm} - ${ldm}/${fdm}`;
    } else {
      return `1/${fdm}/${year} - ${ldm}/${fdm}/${year}`;
    }
  }

  onChangeDateRange(e:Array<Date>) {
    // need format yyyy-
    e[0].setHours(0,0,0,0);
    e[1].setHours(23,59,59,0)
    // console.log(e);
    this.dateRangePickerValues = e;
    this.setDateRange(0);
  }

  getBeautyDateRange(dateFrom:Date, dateTo:Date): string{
    let df = moment(dateFrom).year() == moment().year()? moment(dateFrom).format('DD/MM') : moment(dateFrom).format('DD/MM/YYYY');
    return `${df} - ${moment(dateTo).format('DD/MM/YYYY')}`;
  }

  toggleDropdown(): void {
    this.status.isopen = !this.status.isopen;
  }
 
  change(value: boolean): void {
    this.status.isopen = value;
  }

  onDatepickerHidden(event){
    this.status.isopen = false;
  }
}