import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IAuth, ILoginData, IUser } from 'app/interfaces';
import { modifyComapniesData, getOS } from 'app/utilities';
import { URL_LOGIN, URL_LOGOUT, URL_CHANGE_PASSWORD } from 'app/variables';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable()
export class AuthService {
    //configurations
    private _loginPeriodAllowed = 24; //hour -- if user revisit site within 8 hours, no need to login again
    //data holder
    isLoggedIn = false;
    redirectUrl: string; // store the URL so we can redirect after logging in
    private _authLocalStorageKeyName = 'authenticationDataPrivate';
    private _auth : IAuth = { loginStatus: false, lastLogin: null, user: { email:null, password: null, token: null}};



    constructor(
        private router: Router,
        private http: HttpClient
    ) {
    }

    saveDataOnRefreshPage(){
        if(this.isSessionAuthenticated()) {
            localStorage.setItem(btoa(this._authLocalStorageKeyName), btoa(JSON.stringify(this._auth)));
        }
    }

    loadDataOnInitPage(){
        try{
            this._auth =  JSON.parse( atob( localStorage.getItem(btoa(this._authLocalStorageKeyName))));
            // console.log(this._auth);
        }catch{}
        // finally{
        //     localStorage.removeItem(btoa(this._authLocalStorageKeyName));
        // }
    }

    isFreshAuth():boolean{
        try {
            JSON.parse( atob( localStorage.getItem(btoa(this._authLocalStorageKeyName))));
            return false;
        } catch (error) {
            return true;
        }
    }

    isSessionValid():boolean{
        if( this._auth.lastLogin == null) return false;
        let last = moment(this._auth.lastLogin);
        let hour = moment.duration(last.diff(moment())).asHours();
        if(Math.abs(hour) > this._loginPeriodAllowed) //expired
            return false;
        return true;
    }

    setAuthStatus(loginStatus:boolean){
        if(loginStatus != null)
            this._auth.loginStatus = loginStatus;
        //reset login timestamp
        this._auth.lastLogin = loginStatus?  new Date() : null;
    }
    getAuthStatus(){
        return this._auth.loginStatus;
    }

    setAuthUser(email: string, password: string, token: string){
        if(email && password)
        this._auth.user = {email: email, password: password, token: token};
    }
    getAuthUser(): IUser{
        return this._auth.user;
    }

    isSessionAuthenticated(): boolean{
        if (this._auth.loginStatus == undefined || this._auth.loginStatus == null || this._auth.loginStatus == false || !this.isSessionValid())
            return false;

        return this._auth.loginStatus;
    }

  
    login(email: string, password: string, attempt: number): Promise<ILoginData> {
			const headers = new HttpHeaders()
				.set('Authorization', 'Basic ' + btoa(email + ':' + password)); 

        return this.http.get(URL_LOGIN, {headers: headers})
            .pipe(
                map((data: ILoginData) => {
                    if (data.loginStatus == 1) {
                        this.setAuthStatus(true);
                        data.companies = modifyComapniesData(data.companies);
                        let os = getOS();
                        if( os == 'iOS' || os == 'Android'){
                            this.saveDataOnRefreshPage();
                        }
                    } else {
                        this.setAuthStatus(false);
                        this.setAuthUser('', '', '');
                        this.router.navigate(['/account/login']);
                    }
                    return data;
                })
            )
            .toPromise();
    }

    async logout(): Promise<void> {
        this.setAuthStatus(false);
        let res = await this.http.jsonp(URL_LOGOUT, 'callback').toPromise()
        .catch(err => {
            console.log(err);
            this.setAuthStatus(false);
            this.setAuthUser('', '','');
            localStorage.removeItem(btoa(this._authLocalStorageKeyName));
            let os = getOS();
            if (os == 'iOS' || os == 'Android') {
                this.saveDataOnRefreshPage();
            }
            this.router.navigate(['/account/login']);
            window.location.reload();
        });

        // this.http.jsonp.call(URL_LOGOUT).subscribe(res => {
        // },
        //     err => {debugger
        //         console.log(err);
        //         if (err.status === 200) {
        //             this.setAuthStatus(false);
        //             this.setAuthUser('', '');
        //             localStorage.removeItem(btoa(this._authLocalStorageKeyName));
        //             let os = getOS();
        //             if( os == 'iOS' || os == 'Android'){
        //                 this.saveDataOnRefreshPage();
        //             }
        //             this.router.navigate(['/account/login']);
        //             window.location.reload(true);
        //         }
        //     });
    }

    _catchAPIError(err){
        console.log(err);debugger
        return throwError(err);
    }

    changePassword(auth: IUser ,oldPassword: string, newPassword: string): Observable<any> {

        const headers = new HttpHeaders()
            .set('Authorization', 'Bearer ' + auth.token);

        //body formData
        let body = new FormData();
        body.append('oldpassword', oldPassword);
        body.append('newpassword', newPassword);

        return this.http.post(`${URL_CHANGE_PASSWORD}/`,body, {headers: headers});
    }


}
