import { ICompanyF } from 'app/interfaces';
import { Injectable } from '@angular/core';


@Injectable()
export class UtilsService {

    constructor() { }


    determineGroupTitle(company: ICompanyF): String {
        // find out if select all or not
        let selectedGroupTitle = '';
        if (this.isAllDomainsSelected(company)) { return 'All Sites Data'; }
        else {
            let selectedCount = 0;
            for (let i in company.domains) {
                company.domains[i].selected === true ? selectedCount++ : null;
            }
            // console.log('select count ' + selectedCount);

            if (selectedCount > 1) {
                // multiple site
                return `${selectedCount} Sites Data `;
            } else {
                // sigle site

                // same time looking for too long name (15+char)
                // and trim it to 11char + ..
                selectedGroupTitle = company.domains.find(x => x.selected == true).domainName;
                if (selectedGroupTitle.length <= 9) {
                    return selectedGroupTitle + ' Data';
                }
                // if (selectedGroupTitle.length >= 15) {
                //     // this.selectedGroupTitle = this.selectedGroupTitle.slice(0, 12) + '...';
                // }

            }
        }

    }

    isAllDomainsSelected(company: ICompanyF): boolean {
        if (company.domains.length > 1) {
            let slt = company.domains.every(x => x.selected == true);
            if (slt == false) return false;
            else return true;
        } else {
            return false;
        }
    }

}
