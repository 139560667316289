import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { filter, map } from 'rxjs/operators';

declare type PageTitle = 'Overview' | 'Reports'| 'Management' | 'Banner Advertisments' | 'Settings' | 'Banner Advertisments' | 'Settings' | 'Period comparison' | 'Reports' | 'Banner Form' | 'Crawled Pages' | 'Excluded Pages';

@Component({
  selector: 'app-nav-control',
  templateUrl: './app-nav-control.component.html'
})
export class AppNavControlComponent implements OnInit {
  title:PageTitle;
  showBackBtn = true;
  showDynamicRoute = false;
  showDataDateRange = true;
  dynamicRoute: string;


  constructor(
    public route: ActivatedRoute,
    private router: Router
  ) {
    this.router
      .events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.route.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data['title']) {
              return child.snapshot.data['title'];
            } else {
              return null;
            }
          }
          return null;
        }
      )).subscribe((title: any) => {
        this.title = title; 
        let noBackButtonPages: Array<PageTitle> = ['Overview', 'Reports', 'Management', 'Banner Advertisments', 'Settings', 'Crawled Pages', 'Excluded Pages'];
        let noDatePickerPages: Array<PageTitle> = ['Banner Advertisments', 'Settings', 'Period comparison', 'Reports', 'Banner Form', 'Crawled Pages', 'Excluded Pages'];

        //console.log(title);
        if(noBackButtonPages.indexOf(title) > -1){
          
          if(noDatePickerPages.indexOf(title) > -1) 
            this.showDataDateRange = false;
          else this.showDataDateRange = true;
          

          this.showBackBtn = false;
          this.showDynamicRoute = false;
        }else 
          this.showBackBtn = true;
        
        this.showDataDateRange = noDatePickerPages.indexOf(title) > -1? false : true;

        if(title == 'Related Keyword'){
          this.setRelatedKeywordNavResources();
        }else if(title == 'Popular search' || title == 'Recent search'){
          this.setSearchTermNavResources();
        }else{
          this.showDynamicRoute = false;
        }
      });
    }
    
    ngOnInit() {
      
    }
    
    setRelatedKeywordNavResources(){
      this.showDynamicRoute = true;
      this.showBackBtn = false;
      this.dynamicRoute = '/management';
    }
    
    setSearchTermNavResources(){
      this.showDynamicRoute = true;
      this.showBackBtn = false;
      this.dynamicRoute = '/reports';
  }

  switchBackBtn(){
    this.showDynamicRoute = !this.showBackBtn
  }

  back() {
    if(this.title == 'Period comparison')
      this.router.navigate(['/reports']);
    else
    window.history.back();
  }

}
