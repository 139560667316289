<div class="nav-control">
  <ul class="nav-control-ul">
    <li class="nav-btn">
      <span>
        <a *ngIf="showBackBtn" (click)="back()">
          <i class="icon-arrow-left"></i>
        </a>
        <a *ngIf="showDynamicRoute" [routerLink]="dynamicRoute">
          <i class="icon-arrow-left"></i>
        </a>
      </span>

      <h3 class="nav-justified">{{title}}</h3>
    </li>

    <li *ngIf="showDataDateRange" class="daterange-li">
        <date-range></date-range>
    </li>
  </ul>



  <!-- <span>
  </span>

  <div  class="datarange-container">
    
  </div> -->

</div>
