import { Component } from '@angular/core';
import { AppStateService } from 'app/services';

@Component({
  selector: 'app-dashboard',
  template: '<app-spinner *ngIf="appState.spinner | async"></app-spinner><router-outlet></router-outlet>',
})
export class SimpleLayoutComponent {
      constructor(
        public appState: AppStateService
      ){}
 }
