import { environment } from "environments/environment.prod";

export const navigation = [
  {
    name: 'Search Overview',
    url: '/overview',
    icon: 'icon-grid',
    // badge: {
    //   variant: 'secondary',
    //   text: environment.version
    // }
  },
  {
    name: 'Analytics',
    url: '/reports',
    icon: 'icon-chart',
    children: [
      {
        name: 'Recent Search',
        url: '/reports/recentsearch',
        icon: 'angle-right'
      },
      {
        name: 'Popular Search',
        url: '/reports/topsearchterm',
        icon: 'angle-right'
      },
      {
        name: 'Recent Search',
        url: '/reports/period-comparison',
        icon: 'angle-right'
      }
    ]
    // badge:{
    //   variant: 'primary',
    //   text: 'NEW'
    // }
  },
  {
    name: 'Search Tools',
    icon: 'icon-wrench',
    children:[
      // {
      //   name: 'Crawled Pages',
      //   icon: 'icon-docss',
      //   url: '/tools/crawled-pages'
      // },
      //{
      //   name: 'Excluded Pages',
      //   icon: 'fa fa-search-minuss',
      //   url: '/tools/excluded-pages'
      // },
      {
        name: 'Banner Ads',
        url: '/bannerads',
        icon: 'cus-icon speakerr',
        // badge:{
        //   variant: 'warning',
        //   text: 'DEV'
        // }
      },
    ]
  },
  // {
  //   name: 'Management',
  //   url: '/management',
  //   icon: 'icon-wrench',
  //   badge: {
  //     variant: 'info',
  //     text: 'FUTURE'
  //   }
  // },
  {
    title: true,
    userLogin: true,
    name: `Application`,
    class: 'mt-auto'
  },
  {
    event: true,
    name: 'Give Feedback',
    url: 'param',
    icon: 'icon-speech'
  },
  {
    name: 'User Setting',
    url: '/settings',
    icon: 'icon-settings'
  },
  {
    name: 'Log out',
    url: '/account/logout',
    icon: 'icon-lock'
  }
];
