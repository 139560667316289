import { ICompanyF, ICompanySelected } from "app/interfaces";

export function objNotEmpty(obj): boolean {
    if (obj == null) return false;
    else if (Object.keys(obj).length !== 0 && obj.constructor === Object) return true; // not empty
    else return false;  // empty 
}

export function isDefined(sthToCheck) { if (sthToCheck == undefined) return false; else return true; }




export function modifyComapniesData(rawComs: Array<ICompanyF>): Array<ICompanyF> {
    rawComs.forEach((company, i) => {
        company.domains.forEach((domain, j) => {
            // Trim domain name to fit UI button width
            let tempStr = domain.domainName;
            tempStr = tempStr.replace(/www./i, '');
            tempStr = tempStr.replace(/https:\/\//i, '');
            tempStr = tempStr.replace(/http:\/\//i, '');
            // tempStr = tempStr.replace(/.co.nz/i, '').replace(/.com/i,'');
            // switch (tempStr) {
            //     case 'castleparcels': tempStr = 'Castle Parcels'; break;
            //     case 'nowcouriers': tempStr = 'NOW Couriers'; break;
            //     case 'nowextras': tempStr = 'NOW Extras'; break;
            //     case 'posthaste': tempStr = 'Post Haste'; break;
            //     case 'phlextras': tempStr = 'PHL Extras'; break;
            // }
            // if(tempStr.length > 15){
            //     tempStr = tempStr.slice(0,13) + '...';
            // }

            domain.domainName = tempStr;
        });
        company.domains[0].default = true;

        
        
    });



    // device detection
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|iphone|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {

        rawComs.forEach((item, i) => {
            if (item.companyName == 'New Zealand Couriers') {
                rawComs[i].companyName = 'NZ Couriers';
            }
        });
    }

    return rawComs;
}

export function mergeSelection(rawComs: Array<ICompanyF>, selected: ICompanySelected): Array<ICompanyF> {

    // reset old seletion
    rawComs.forEach(company => {
        company.selected = false;
        company.domains.forEach(domain => {
            domain.selected = false;
        });
    });

    if (selected == null || selected.selectedCompanyId == 0) {
        let tempDomainId = [];
        rawComs.forEach(company => {
            company.domains.forEach(domain => {
                tempDomainId.push(domain.domainId);
            });
        });
        selected = { selectedCompanyId: rawComs[0].companyId, selectedDomains: tempDomainId };
    }
    // add selected to selected company
    try {
        rawComs.find(x => x.companyId == selected.selectedCompanyId).selected = true;

    } catch (error) {

    }
    // set domain selection
    if (selected.selectedDomains.length == 0) {
        // if no selection .. set first domain as selected
    } else {
        // if there is seleted list ... loop and set seletion to it
        for (let i = 0; i < selected.selectedDomains.length; i++) {

            rawComs.forEach(com => {
                try {
                    com.domains.find(domain => domain.domainId == selected.selectedDomains[i]).selected = true;
                } catch{ }
            });



        }
    }

    return rawComs;
}

/** 
 *  Convert JS Date object to 'dd-mm-yyyy' string format 
*/
// export function convertDateToUrlFormat(date: Date): string {
//     return date.toLocaleDateString().replace(/\//g, "-");
// }

/** 
 *  Find selected company
*/
export function findSelectedCompany(companyList: Array<ICompanyF>) {
    return companyList.find(company => company.selected == true);
}

export function isValidURL(url: string) {
    let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return pattern.test(url);
}

export function isValidDate(date: Date){
    return !isNaN(date.getTime());
}

export function browserDetector(){
    let ua = navigator.userAgent, tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
    return M.join(' ');
}


export function getOS() {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;
  
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'MacOS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }
  
    return os;
}
