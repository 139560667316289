import { Component } from '@angular/core';
import { environment } from 'environments/environment.prod';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html'
})

export class AppFooterComponent { 
  version = environment.version;
  year = new Date().getFullYear();
}
