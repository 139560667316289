import { Injectable } from '@angular/core';

import { ICompanyF, ICompanySelected } from 'app/interfaces';



@Injectable()
export class CommonService {



    // selectedCompany: ICompanyF = { companyId: null, companyName: 'loading...', domains: null, selected: null, default: null };
    // selectedGroupTitle = 'All Domains Data';
    // availableCompanies: Array<ICompanyF>;
    // appSettingDataHolder: ILoginData;


    constructor() {
    
    }

    // onSelectDomains(index: number): any{
    //     let tempBool = !this.selectedCompany.domains[index].seleted;
    //     this.selectedCompany.domains[index].seleted = tempBool;
    //     // setTimeout(() => {
    //     // });
    //     if (this.noDomainSeleted()) { this.checkDefaultSubSite(); }
    //     // this.determineGroupTitle();
    //     // this.updateSelectionChange();
    //     return {groupTitle: this.determineGroupTitle(), companySelected: this.updateSelectionChange()}
    // }

    // onSelectCompany(index: number) {
    //     for (let i in this.availableCompanies) {
    //         this.availableCompanies[i].selected = false;
    //     }
    //     this.availableCompanies[index].selected = true;
    //     this.selectedCompany = this.availableCompanies[index];
    //     this.determineGroupTitle();
    //     this.updateSelectionChange();
    // }

    // disable on UI for now
    // changeDefaultCompany(index: number) {
    //     for (let i in this.availableCompanies) {
    //         this.availableCompanies[i].default = false;
    //     }
    //     this.availableCompanies[index].default = true;
    //     this.determineGroupTitle();
    //     this.updateSelectionChange();
    //     return {groupTitle: this.determineGroupTitle(), companySelected: this.updateSelectionChange()}
    // }






    /*
        Background logic
    */


    findSelectedCompany(availableCompanies: Array<ICompanyF>): ICompanyF {
        try {
            return availableCompanies.find(x => x.selected == true);
        } catch (e) {

        }
    }

    determineGroupTitle(companies: ICompanyF): string {
        // find out if select all or not
        // if (this.isAllDomainsSelected()) { this.selectedGroupTitle = 'All Domains Data'; }
        // else {
            let selectedCount = 0;
            let selectedGroupTitle = '';
            for (let i in companies.domains) {
                companies.domains[i].selected === true ? selectedCount++ : null;
            }
            // console.log('select count ' + selectedCount);

            if (selectedCount > 1) {
                // multiple site
                return `${selectedCount} Sites Data `;
            } else {
                // sigle site

                // same time looking for too long name (15+char)
                // and trim it to 11char + ..
                selectedGroupTitle = companies.domains.find(x => x.selected == true).domainName;
                if (selectedGroupTitle.length <= 9) {
                    return selectedGroupTitle + ' Data';
                }
                if (selectedGroupTitle.length >= 15) {
                    return selectedGroupTitle.slice(0, 12) + '...';
                }

            }
        // }

        return selectedGroupTitle;

         /* TODO: in parent fuction call DataService to update group title */
        //this.dataService.setdomainGroupTitle(this.selectedGroupTitle);

    }

    checkDefaultSubSite(selectedCompany: ICompanyF) {
        const index = selectedCompany.domains.findIndex(x => x.default === true);
        selectedCompany.domains[index].selected = true;
        return selectedCompany;
    }

    isAllDomainsSelected(selectedCompany: ICompanyF): boolean {
        if (selectedCompany.domains.length > 1) {
            let slt = selectedCompany.domains.every(x => x.selected == true);
            if (slt == false) return false;
            else return true;
        } else {
            return false;
        }
    }

    noDomainSeleted(selectedCompany: ICompanyF): boolean {
        // make sure no sub site are unselected 
        return selectedCompany.domains.every(x => x.selected == false);
    }

    updateSelectionChange(availableCompanies: Array<ICompanyF>, selectedCompany:ICompanyF): ICompanySelected {
        // merge selected company to availableCompany
        let index = availableCompanies.findIndex(x => x.companyId ==  selectedCompany.companyId);
        availableCompanies[index] = selectedCompany;
        let tempDomainId = [];
        availableCompanies.forEach( (com, i) => {
            availableCompanies[i].domains.forEach( domain => {
                if(domain.selected) tempDomainId.push(domain.domainId);
            });
        });
        let temp: ICompanySelected = {selectedCompanyId: availableCompanies[index].companyId, selectedDomains:tempDomainId }
        

        return temp;

        /* TODO: in parent fuction call DataService to update COMPANY SELECTION */
        //this.dataService.setCompanySelect(temp);
        

        // this.appSettingDataHolder.companies = this.availableCompanies;
        // setTimeout(() => {
        //     this.dataService.setUserLoginData(this.appSettingDataHolder);
        // }, 10);
    }


}
