import { Injectable } from '@angular/core';
import { ngxCsv } from 'ngx-csv/ngx-csv';

@Injectable()
export class ExportService {

  constructor() { } 

  exportAsCSV(data: Array<any>, fileName:string, options?: any){
    new ngxCsv(data, fileName, options);
  }


}
