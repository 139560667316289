<header class="app-header navbar">
  <!-- <button class="navbar-toggler d-md-down-none" type="button" appSidebarToggler>
    <span class="navbar-toggler-icon"></span>
  </button> -->

    <a class="navbar-brand" 
  [ngClass]="{
    'nzc': selectedCompanyId == 1, 
    'posthaste': selectedCompanyId == 2,
    'nowcouriers': selectedCompanyId == 3, 
    'castle': selectedCompanyId == 4
    }"  href="#">
  </a>
  <app-panel></app-panel>

  <!-- <ul class="nav navbar-nav d-md-down-none">
    <li class="nav-item px-3">
      <a class="nav-link" href="#">Dashboard</a>
    </li>
    <li class="nav-item px-3">
      <a class="nav-link" href="#">Users</a>
    </li>
    <li class="nav-item px-3">
      <a class="nav-link" href="#">Settings</a>
    </li>
  </ul> -->

  <ul class="nav navbar-nav ml-auto">
    <!-- <li class="nav-item d-md-down-none">
      <span class="badge badge-pill badge-info mr-4">BETA Version {{version}}</span>
    </li> -->
    <!-- <li class="nav-item d-md-down-none">
      <a class="nav-link" href="#"><i class="icon-list"></i></a>
    </li> -->
    <!-- <li class="nav-item d-md-down-none">
      <a class="nav-link" href="#"><i class="icon-location-pin"></i></a>
    </li> -->
    <!-- <li class="nav-item d-md-down-none">

    </li> -->
    <!-- <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
        <img src="assets/img/avatars/0.jpg" class="img-avatar" alt="Maker.co.nz"/>
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown"> -->
        <!-- <div class="dropdown-header text-center"><strong>Account</strong></div> -->
        <!-- <a class="dropdown-item" href="#"><i class="fa fa-bell-o"></i> Updates<span class="badge badge-info">42</span></a> -->
        <!-- <a class="dropdown-item" href="#"><i class="fa fa-envelope-o"></i> Messages<span class="badge badge-success">42</span></a> -->
        <!-- <a class="dropdown-item" href="#"><i class="fa fa-tasks"></i> Tasks<span class="badge badge-danger">42</span></a> -->
        <!-- <a class="dropdown-item" href="#"><i class="fa fa-comments"></i> Comment<span class="badge badge-warning">42</span></a> -->
        <!-- <div class="dropdown-header text-center"><strong>Settings</strong></div> -->
        <!-- <a class="dropdown-item" href="#"><i class="fa fa-user"></i> Profile</a> -->
        <!-- <a class="dropdown-item" routerLink="/settings/account"><i class="icon-settings"></i> Setting</a> -->
        <!-- <a class="dropdown-item" href="#"><i class="fa fa-usd"></i> Payments<span class="badge badge-dark">42</span></a> -->
        <!-- <a class="dropdown-item" href="#"><i class="fa fa-file"></i> Projects<span class="badge badge-primary">42</span></a> -->
        <!-- <div class="divider"></div> -->
        <!-- <a class="dropdown-item" href="#"><i class="fa fa-shield"></i> Lock account</a> -->
        <!-- <a class="dropdown-item" (click)="logout()"><i class="fa fa-lock" ></i> Logout</a>
      </div>
    </li> -->
    <!-- <button class="navbar-toggler d-md-down-none" type="button" appAsideMenuToggler>
      <span class="navbar-toggler-icon"></span>
    </button> -->
  </ul>
  <button class="ml-auto navbar-toggler d-lg-none" type="button" appMobileSidebarToggler>
      <span class="navbar-toggler-icon"></span>
    </button>
</header>


