import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICompanyF, ICompanySelected, ILoginData, IAppPreferenceZip } from 'app/interfaces';
import { AuthService, DataService } from 'app/services';
import { getLocalStorage, saveToLocalStorage, browserDetector, getOS } from 'app/utilities';

import * as moment from 'moment';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: `<router-outlet></router-outlet>`
})
export class AppComponent implements OnInit {

  constructor(
    private _as: AuthService,
    private _ds: DataService,
    private _route: Router
  ) {

    // this._initThemeSetting();
 

    // check if auth in localStorage
    _as.loadDataOnInitPage();
    // if(_as.isFreshAuth()) this._route.navigate(['/account/login']);  // if no : go login
    if(!_as.isSessionAuthenticated()) //not authenticated go log-in
      this._route.navigate(['/account/login']);
    // if yes : try to login
    this.loginAsValidSession();
  }

  ngOnInit() {
     document.documentElement.className = browserDetector();
     document.documentElement.className += ' ' + getOS();
  }

  private _initThemeSetting() {
    // let theme = getLocalStorage('theme');
    // if (theme == null) {
    //   saveToLocalStorage('theme', false); // default true = dark theme
    // }
    // if(theme){
    //   document.getElementById('body').classList.remove('theme-light');
    //   document.getElementById('body').classList.add('theme-dark');
    // }else{
    //   document.getElementById('body').classList.remove('theme-dark');
    //   document.getElementById('body').classList.add('theme-light');
    // }
  }

  async loginAsValidSession() {
    let email = this._as.getAuthUser().email;
    let password = this._as.getAuthUser().password;
    if (email != null && password != null) {
      await this._as.login(email, password, 1)
        .then(
        (data: ILoginData) => {
          if (data.loginStatus == 0) this._as.logout();
          else if (data.loginStatus == 1) {
            // set auth status
            this._as.setAuthStatus(true);
            // save login result data
            saveToLocalStorage('rawCompany', data.companies);
            // console.log(data);
            this.getAppPreferences();
            // this.getZipWidget();
          }
        });
    }
  }


  async getAppPreferences() {
    // get and set app preferences
    
    await this._ds.getApiAppPreferences(this._as.getAuthUser()).then(
      res => {
        if(res.companySelect == undefined){
          // if no app setting, will need to initialize new preference
          const rawComs: Array<ICompanyF> = getLocalStorage('rawCompany');
          let tempComs: ICompanySelected = {selectedCompanyId: 0, selectedDomains: []};
          // take the first company in array to be selected company
          tempComs.selectedCompanyId = rawComs[0].companyId;
          for(let domain of rawComs[0].domains){
            tempComs.selectedDomains.push(domain.domainId);
          }
          let newInit:IAppPreferenceZip =  {companySelect:null, dateRange: {datefrom: null, dateto: null, title: 'Today', selected: 1, range: `${moment().format('DD/MM/YYYY')}`}};
          newInit.companySelect = tempComs;
          newInit.dateRange.datefrom = moment().startOf('day').toDate();
          newInit.dateRange.dateto = moment().endOf('day').toDate();
          //set new initialize in dataService
          this._ds.setCompanySelect(newInit.companySelect, this._as.getAuthUser());
          //init dateRange 
          this._ds.setDataDateRange(newInit.dateRange, this._as.getAuthUser());
        }
        else if(res.companySelect.selectedCompanyId != 0){
          this._ds.setAppPreferences(res, this._as.getAuthUser());
          this._ds.setDataDateRange(res.dateRange,  this._as.getAuthUser());
          this._ds.setCompanySelect(res.companySelect, this._as.getAuthUser());
          // console.log(res);
        }
      }
    ).catch(
      e => console.error("On get app preferences network error!") 
    );
  }


  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    // console.log("Auth Service Processing beforeunload...");
    if(this._as.isSessionAuthenticated() == true)
    this._as.saveDataOnRefreshPage();
  }


  
  

}
