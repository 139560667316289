import { Component } from '@angular/core';
import { AppStateService } from 'app/services';
import { environment } from 'environments/environment.prod';

@Component({
  selector: 'app-dashboard',
  templateUrl: './settings-layout.component.html',
  styleUrls: ['./settings-layout.component.scss']
})
export class SettingsLayoutComponent {
  version = environment.version;
  constructor(
    public appState: AppStateService
  ) { }
}
