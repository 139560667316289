


 


<div class="container mt-4">


  <div class="row">

    <div class="col-md-3 order-md-1 mb-4">
      <h3 class="d-flex justify-content-between align-items-center mb-3">
        <span class="text-muted">Settings</span>
      </h3>

      <ul class="list-group mb-3">
        <li class="list-group-item d-flex justify-content-between lh-condensed"
        routerLink="/settings/account" routerLinkActive="active">
     
            <h6 class="my-0">Account</h6>
       
        </li>
        <!-- <li class="list-group-item d-flex justify-content-between lh-condensed"
        routerLink="/settings/theme" routerLinkActive="active">
            <h6 class="my-0">Theme</h6>
        </li> -->

      </ul>
      <button type="button" class="btn btn-block" routerLink="/overview">
        <i class="fa fa-arrow-left"></i> &nbsp; 
        Back to application
      </button>
    </div>

    <div class="col-md-9 order-md-2">
      <router-outlet></router-outlet>

    </div>
  </div>

  <footer class="my-5 pt-5 text-muted text-center text-small">
    <p class="mb-1">&copy; 2018 Maker Design Ltd. &nbsp; | &nbsp; Beta version {{version}}</p>
  </footer>
</div>
