<app-header></app-header>
<div class="app-body">
  <app-sidebar></app-sidebar>
  <!-- Main content --> 
  <main class="main">
    
    <app-nav-control></app-nav-control>

    <div class="container-fluid routeContainer" [@routeAnimation]="getDepth(myOutlet)">
      <app-spinner *ngIf="appState.spinner | async"></app-spinner>
      <router-outlet #myOutlet="outlet"></router-outlet>
    </div><!-- /.conainer-fluid -->

  </main>
</div>
<app-footer></app-footer>
