<div class="app-panel-nav-btn" [ngClass]="{'focus': addSitePanel}" (click)="addSitePanel = !addSitePanel"  >
  <div class="panel-company-title">{{printLimitLengthString(selectedCompany.companyName, 15)}}</div>
  <span class="group-title">{{printLimitLengthString(selectedGroupTitle, 18)}}</span>
  <i class="fa fa-sort-down" aria-hidden="true"></i>
</div>
<!-- (mouseenter)="addSitePanel = !addSitePanel" -->


<div class="app-panel-container" [ngClass]="{'show': addSitePanel}" *ngIf="addSitePanel" >
  <div class="app-panel-backdrop" (click)="addSitePanel = false; onAddSite = true;" >
  </div>
  <div class="app-panel" [ngClass]="{'panel-animate-enter':addSitePanel == true}" (mouseenter)="addSitePanel = true;" (mouseleave)="addSitePanel = false;">


    <div *ngIf="onAddSite; else showOnAddSitePane">

      <!-- header -->
      <nav class="navbar navbar-light panel-header">
        <span class="navbar-text">
          <span class="panel-title font-lg">Select company</span>
        </span>
        <!-- <a class="navbar-text panel-btn" (click)="onAddSite = false">
          <i class="fa fa-plus" aria-hidden="true"></i> Add domain
        </a> -->
      </nav>

      <!-- body -->
      <div class="panel-body">

        <div class="panel-column-container">


          <div class="panel-column maincompany">
            <div class="header">
              Company
            </div>

            <ul class="list" *ngFor="let site of availableCompanies; let i = index;">
              <li [ngClass]="{'active': site.selected}" (click)="onSelectCompany(i)">{{site.companyName}}
                <!-- <span class="panel-action-item pull-right" *ngIf="site.default" placement="left">
                  <i class="fa fa-bookmark" aria-hidden="true"></i>
                </span>
                <a class="panel-action-item pull-right" *ngIf="!site.default" tooltip="Make default" placement="top" (click)="changeDefaultCompany(i)">
                  <i class="fa fa-bookmark-o" aria-hidden="true"></i>
                </a> -->
                <span class="panel-action-item pull-right" *ngIf="site.selected" style="margin-right:5px;">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </span>
              </li>
            </ul>

          </div>



          <div class="panel-column subcompany ">
            <div class="header">
              Domain

            </div>

            <ul class="list" *ngFor="let domain of selectedCompany.domains; let i = index;">
              <li (click)="onSelectDomains(i)">{{domain.domainName}} 
                <span class="default-indicator float-right font-xxs pt-1 d-none d-sm-block" *ngIf="domain.default"> default</span> 
              </li>
            </ul>
          </div>



          <div class="panel-column action">
            <div class="header pl-0">
              Group
            </div>
            <!-- <ul class="list">
              <li>
                <input type="checkbox" [checked]="selectedCompany.selectedAllDomains" (click)="selectAllSite()">
              </li>
            </ul> -->
            <ul class="list">
              <li *ngFor="let domain of selectedCompany.domains; let i = index;" >
                  <input type="checkbox" [ngModel]="domain.selected" (click)="onSelectDomains(i)">
              </li>

            </ul>
          </div>

        </div>
      </div>

    </div>


    <ng-template #showOnAddSitePane>
      <div>
        <nav class="navbar navbar-light panel-header">
          <span class="navbar-text">
            <span class="panel-title font-lg ">Add Analytics Site</span>
          </span>

          <a class="navbar-text panel-btn" (click)="onAddSite = true">
            Done
          </a>
        </nav>
      </div>
    </ng-template>

  </div>
</div>
