
export function getLocalStorage(name: string) {
    let temp = JSON.parse(localStorage.getItem(name));
    // try {
    //     return J
    // } catch{
    //     return undefined;
    // }
    return temp;
}

export function saveToLocalStorage(name: string, data: any) {
    localStorage.setItem(name, JSON.stringify(data));
    return data;
}
