import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-spinner',
    template: `<div class="app-spinner" ><div class="circle"></div></div>`,
    styles:[`
    .circle {
        box-sizing: border-box;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        border: 7px solid rgba(255, 255, 255, 0.2);
        border-top-color: rgb(146, 146, 146);
        animation: spin 1s infinite linear;    
      }

      @keyframes spin { 
        100% { 
          transform: rotate(360deg); 
        } 
      } 
    `]
    // styleUrls: ['./app.spinner.component.scss']
})

export class AppSpinnerComponent implements OnInit {
    @Input() show = false;

    ngOnInit(){
        // console.log(this.show);
    }
}