import { Component, OnInit, ViewChild, ElementRef, Input, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-circular-canvas',
  templateUrl: './circular-canvas.component.html',
  styleUrls: ['./circular-canvas.component.scss']
})
export class CircularCanvasComponent implements OnInit, AfterViewInit {

  // a reference to the canvas element from our template
  @ViewChild('canvas', { static: true }) public canvas: ElementRef;
  // setting a width and height for the canvas
  @Input() public width = 300;
  @Input() public height = 300;
  @Input() public title = '';
  @Input() public unit = 'Searches';
  @Input() public value = 0;
  @Input() public gaugeValue = undefined;
  @Input() public baseValue = 0;
  @Input() public radius = 60;
  @Input() public borderColor = '#63c2de';
  @Input() public lineWidth = 10;
  @Input() public type = 'default';
  @Input() public disabled = false;
  @Input() public date = 'loding...';
  @Input() public styles = '';
  @Input() public disableHover = false;
  @Input() public valueFontSize = 35;
  @Input() public unitFontSize = 10;
  @Input() public fontWeight = 'lighter';


  valuePercent = 0;

  private _canvasEl: HTMLCanvasElement ;
  private _halfW ;
  private _halfH;
  private _percentage = 0;
  private _context: CanvasRenderingContext2D;



  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(){
    this._canvasEl = this.canvas.nativeElement;
    this._context = this._canvasEl.getContext('2d');
    this._halfW = this._canvasEl.width /2;
    this._halfH = this._canvasEl.height /2;



    this._context.lineWidth = this.lineWidth;
    this._context.lineCap = 'round';
    this._context.textAlign = 'center';
    

    // draw base ring
    this._context.translate(this._halfW,this._halfH);
    this._context.rotate((-1 / 2) * Math.PI);
    this._context.translate(-this._halfW, -this._halfH);
    this._context.beginPath();
    this._context.strokeStyle = '#ECEFF1';
    this._context.arc(this._halfW, this._halfH, this.radius, 0, Math.PI * 2, false);

    this._context.stroke();
    
    this.draw();


  }

    /**
   * Draws something using the context we obtained earlier on
   */
   draw() {
    try {
      /* Calculate percentage */
      if (this.type == 'summary' || this.type == 'static') this._percentage = 100;
      else if(this.type == 'custom'){
        this._percentage = this.gaugeValue / this.baseValue * 100;
      }
      else if (this.baseValue == 0) this._percentage = 0;
      else {
         this._percentage = this.calculatePercentage(this.value, this.baseValue);
         this.valuePercent = this._percentage;
      }

      this._context.clearRect(0, 0, this.width, this.height);
      this._context.beginPath();
      
      this.disabled? this._context.strokeStyle = '#eee' : this._context.strokeStyle = '#bebebe';
      this._context.arc(this._halfW, this._halfH, this.radius, 0, Math.PI * 2, false);
      

      
      this._context.stroke();
      this._context.beginPath();
      this.disabled? this._context.strokeStyle = "#bebebe" : this._context.strokeStyle = this.borderColor;
      this._context.arc(this._halfW, this._halfH, this.radius, 0, Math.PI * 2 * (this._percentage * 0.01), false);
      this.update();
      if(this.type == 'summary'){
        let gradient = this._context.createLinearGradient(0,500,0, 0);
        gradient.addColorStop(0, '#20a8d8');
        gradient.addColorStop(0.5, '#35CA94');
        gradient.addColorStop(1, '#1F4C95');
        this._context.strokeStyle = gradient;
      }
      this._context.stroke();
      
    } catch (error) { }
  }

  update() {
    this._context.save();
    this._context.translate(this._halfW, this._halfH);
    this._context.rotate((1 / 2) * Math.PI);
    this._context.translate(-this._halfW, -this._halfH);


    if(this.type == 'summary'){
      
        // let gradient = this._context.createLinearGradient(0,500,0, 0);
        // gradient.addColorStop(0, '#c0e674');
        // gradient.addColorStop(1, '#40d6a5');
        // this._context.strokeStyle = gradient;
      
      // this.context.font = 'normal 18px Roboto';
      // this.context.fillText('Total', this.halfW, this.halfH-40); 
      // this.context.font = 'bold 45px Roboto';
      // this.context.fillText(this.value.toString(), this.halfW, this.halfH+10);
      // this.context.font = 'normal 24px Roboto';
      // this.context.fillText(this.unit, this.halfW, this.halfH+40);
    }else{
      // this.context.font = 'bold 30px Roboto';
      // this.context.fillText(this.value.toString(), this.halfW, this.halfH);
      // this.context.font = '12px Roboto';
      // this.context.fillText(this.unit, this.halfW, this.halfH+20);
    }


    this._context.restore();
  }

  ngOnChanges(){
    
    this.draw();
  }

  private calculatePercentage(dividend: number, divisor: number):number{
    return Math.round((dividend / divisor ) * 100);
  }


}
