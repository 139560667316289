import { environment } from "environments/environment.prod";

export const APP_SETTING_CACHE = 'appSettings';
export const URL_MAKER_SEARCH_DOMAIN = environment.searchEngineUrl;
// export const URL_MAKER_SEARCH_DOMAIN = 'https://search-dev.maker.co.nz';
export const URL_KIMI_ASSET = `https://assets.kimisearch.com`;
export const URL_MAKER_API = URL_MAKER_SEARCH_DOMAIN+'/api';

//account
export const URL_LOGIN = `${URL_MAKER_API}/login`;
export const URL_LOGOUT = `${URL_MAKER_API}/logout`;
export const URL_CHANGE_PASSWORD = `${URL_MAKER_API}/set/data/password/oldpassword`;
export const URL_SEND_FEEDBACK = `${URL_MAKER_SEARCH_DOMAIN}/feedback/send/`;
export const URL_GET_PREFERENCES = `${URL_MAKER_API}/get/data/preferences`;
export const URL_SET_PREFERENCES = `${URL_MAKER_API}/set/data/preferences/value/`;

// search
export const URL_SEARCH_TERM = `${URL_MAKER_API}/get/data/search-stats`;
export const URL_RAW_SEARCH = `${URL_MAKER_API}/get/data/recent-searches`;
export const URL_TOTAL_SEARCH = `${URL_MAKER_API}/get/data/total-searches`;

//Banner
export const URL_BANNER_GETALL = `${URL_KIMI_ASSET}/banner/getall`;
export const URL_BANNER_GET = `${URL_KIMI_ASSET}/banner/get`;
export const URL_BANNER_GET_GLOBAL = `${URL_KIMI_ASSET}/banner/get_global_banners`;
export const URL_BANNER_POST = `${URL_KIMI_ASSET}/banner/process`; 
export const URL_BANNER_DELETE = `${URL_KIMI_ASSET}/banner/delete`; 
export const URL_BANNER_PUBLISH = `${URL_KIMI_ASSET}/banner/get_published_banner`;
export const URL_BANNER_UNPUBLISH = `${URL_KIMI_ASSET}/banner/get_unpublished_banner`;
export const URL_IMAGE_BANNER_DELETE = `${URL_KIMI_ASSET}/banner/remove_image`;
export const BANNER_PLACEHOLDER_IMG_PATH = '/assets/img/placeholder.png';

// unused
export const URL_GET_WIDGETS = `${URL_MAKER_API}/get/data/widgets`;
export const URL_SET_WIDGETS = `${URL_MAKER_API}/set/data/widgets/value/`;


export const GROUPBY_METHODS = ['hour','date','week','month','year'];
export enum E_GROUPBY_METHODS{ HOUR, DATE, WEEK, MONTH, YEAR};

export const APP_DATE_PATTERN = 'YYYY-MM-DD kk:mm:ss';
// https://search-dev.maker.co.nz/api/set/data/widgets/value/serialised-string-to-be-saved-in-database
// https://search-dev.maker.co.nz/api/set/data/preferences/value/serialised-string-to-be-saved-in-database
// https://search-dev.maker.co.nz/api/get/data/widgets/
// https://search-dev.maker.co.nz/api/get/data/preferences/
