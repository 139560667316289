import { Component, OnInit } from '@angular/core';
import { DataService } from 'app/services';
import { objNotEmpty } from 'app/utilities';
import { environment } from 'environments/environment.prod';


@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html'
})
export class AppHeaderComponent implements OnInit { 
  version = environment.version;
  selectedCompanyId = 1 ;

  constructor(private dataService: DataService){
  }
  
  ngOnInit() {
    this.dataService.companySelection$.subscribe(
      data => {
        if (objNotEmpty(data)) {
          this.selectedCompanyId = data.selectedCompanyId;
          // this.selectedCompanyId = findSelectedCompany(data.companies).companyId;
          // console.log(this.selectedCompanyId);
        }
      });

  }



}
