<div class="daterange-picker-container">

  <div class="btn-group" dropdown #dropdown="bs-dropdown" [isOpen]="status.isopen" (isOpenChange)="change($event)" [autoClose]="false">
    
    <button dropdownToggle class="btn btn-primary daterange-picker-btn" type="button" style="border-top-right-radius: 3px; border-bottom-right-radius: 3px;">
  
      <div class="title">
          <!-- <span class="calendar-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20">
                <defs>
                  <style>
                    .cls-1 {
                      fill: #fff;
                    }
              
                    .cls-2 {
                      clip-path: url(#clip-path);
                    }
                  </style>
                  <clipPath id="clip-path">
                    <rect id="Rectangle_23" data-name="Rectangle 23" class="cls-1" width="20" height="20" transform="translate(1690 83)"/>
                  </clipPath>
                </defs>
                <g id="Mask_Group_6" data-name="Mask Group 6" class="cls-2" transform="translate(-1690 -83)">
                  <g id="calendar" transform="translate(1690 83)">
                    <g id="Group_22" data-name="Group 22">
                      <g id="Group_21" data-name="Group 21">
                        <rect id="Rectangle_31" data-name="Rectangle 31" class="cls-1" width="5" height="5" transform="translate(10 11)"/>
                        <path id="Path_26" data-name="Path 26" class="cls-1" d="M17,2H16V0H14V2H6V0H4V2H3A1.991,1.991,0,0,0,1.01,4L1,18a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V4A2,2,0,0,0,17,2Zm0,16H3V7H17Z"/>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              
          </span> -->
        {{dateRange.title}} 
        <i class="fa fa-sort-down" aria-hidden="true"></i> 
      </div>
      <div class="date">Data for {{dateRange.range}}</div>
           
    </button>
    
    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
      <li role="menuitem">

        <a class="dropdown-item" (click)="setDateRange(1)">
          <i class="fa fa-check" [ngStyle]="{'opacity': dateRange.selected == 1? 1: 0}"></i> Today</a>
      </li>
      <li role="menuitem">
        <a class="dropdown-item" (click)="setDateRange(2)">
          <i class="fa fa-check" [ngStyle]="{'opacity': dateRange.selected == 2? 1: 0}"></i>This Week</a>
      </li>
      <li role="menuitem">
        <a class="dropdown-item" (click)="setDateRange(3)">
          <i class="fa fa-check" [ngStyle]="{'opacity': dateRange.selected == 3? 1: 0}"></i>This Month</a>
      </li>
      <li class="divider dropdown-divider m-0"></li>
      <li role="menuitem">

        
        <a class="dropdown-item" (click)="drp.toggle()">
          <i class="fa fa-check" [ngStyle]="{'opacity': dateRange.selected == 0? 1: 0}"></i>
          Custom
        </a>
        <input 
        class="form-control" 
        bsDaterangepicker 
        #drp="bsDaterangepicker" 
        [container]="'body'" 
        (bsValueChange)="onChangeDateRange($event)" 
        (onHidden)="onDatepickerHidden($event)" 
        [bsConfig]="bsConfig" 
        [maxDate]="maxDate"
        [minDate]="minDate"
        placement="bottom" 
        style="opacity: 0;padding: 0;height: 0;width: 0;margin: 0;">
      </li>
    </ul>
    <br>
  
  </div>
</div>
