import { APP_SETTING_CACHE } from 'app/variables';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit,HostListener } from '@angular/core';
import { AppStateService, DataService, AuthService } from 'app/services';
import { trigger, transition, group, query, style, animate} from '@angular/animations';
import { objNotEmpty, saveToLocalStorage } from 'app/utilities';


@Component({
  selector: 'app-dashboard',
  templateUrl: './full-layout.component.html',
  animations:[
    trigger('routeAnimation', [

      transition('1 => 2 , 2 => 3 , 3 => 4 , 2 => 4',[
        style({height: '!'}),
        query(':enter', style({transform: 'translateX(100%)'})),
        query(':enter, :leave', style({ position:'absolute', top: 0, left: 0, right:0})),
        group([
          query(':leave', [animate('0.3s cubic-bezier(.35,0,.25,1)',style({transform:'translateX(-100%'}))]),
          query(':enter',[animate('0.3s cubic-bezier(.35,0,.25,1)',style({transform:'translateX(0'}))])
        ])
      ]),
      transition('2 => 1, 3 => 2, 4 => 3 , 4 => 2',[
        style({height: '!'}),
        query(':enter', style({transform: 'translateX(-100%)'})),
        query(':enter, :leave', style({ position:'absolute', top: 0, left: 0, right:0})),
        group([
          query(':leave', [animate('0.3s cubic-bezier(.35,0,.25,1)',style({transform:'translateX(100%'}))]),
          query(':enter',[animate('0.3s cubic-bezier(.35,0,.25,1)',style({transform:'translateX(0'}))])
        ])
      ])
    ])
  ]
})
export class FullLayoutComponent implements OnInit { 

  spinner = false;

  constructor(
    public appState: AppStateService,
    private activatedRoute: ActivatedRoute,
    private _as: AuthService,
    private _ds: DataService
  ){
        // console.log('constructor FullContainer');
  }


  // @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
  //   console.log("Processing beforeunload...");

  // }

  ngOnInit(){
    // console.log('init FullContainer');
    // get new app preference
    this._ds.getApiAppPreferences(this._as.getAuthUser()).then( res => {

      // this._ds.setDataDateRange(res.dateRange);
      // this._ds.setSelectedCompany(res.selectedCompanyId);
    });
   
  }

  getDepth(outlet){
    return outlet.activatedRouteData['depth'];
  }


}
