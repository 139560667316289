import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URL_SEND_FEEDBACK } from 'app/variables';
import { IUser } from 'app/interfaces';

@Injectable()
export class EmailService {

  constructor(
    private http: HttpClient
  ) {
   }

  postFeedback(body:HttpParams, auth: IUser): Observable<any>{
    //  API currently not accept Authorization headers 
    // const headers = new HttpHeaders()
    //   .set('Authorization', `Bearer ${auth.token}`);
    return this.http.post(URL_SEND_FEEDBACK, body);
  }

}
