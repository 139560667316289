import { style } from '@angular/animations';
import { Component, ElementRef, Input, OnInit, Renderer2, TemplateRef } from '@angular/core';
import { HttpParams } from '@angular/common/http';

// Import navigation elements
import { navigation } from './../../_nav';

@Component({
  selector: 'app-sidebar-nav',
  template: `
    <nav class="sidebar-nav">
      <ul class="nav">
        <ng-template ngFor let-navitem [ngForOf]="navigation">
          <li *ngIf="isDivider(navitem)" class="nav-divider"></li>
          <ng-template [ngIf]="isTitle(navitem)">
            <app-sidebar-nav-title [title]='navitem'></app-sidebar-nav-title>
          </ng-template>
          <ng-template [ngIf]="!isDivider(navitem)&&!isTitle(navitem)">
            <app-sidebar-nav-item [item]='navitem'></app-sidebar-nav-item>
          </ng-template>
        </ng-template>
      </ul>
    </nav>`
})
export class AppSidebarNavComponent {

  public navigation = navigation;

  public isDivider(item) {
    return item.divider ? true : false
  }

  public isTitle(item) {
    return item.title ? true : false
  }

  constructor() { }
}

import { Router } from '@angular/router';
import { AuthService, EmailService } from 'app/services';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sidebar-nav-item',
  template: `
    <li *ngIf="!isDropdown(); else dropdown" [ngClass]="hasClass() ? 'nav-item ' + item.class : 'nav-item'">
      <app-sidebar-nav-link [link]='item'></app-sidebar-nav-link>
    </li>
    <ng-template #dropdown>
      <li [ngClass]="hasClass() ? 'nav-item nav-dropdown ' + item.class : 'nav-item nav-dropdown'"
          [class.open]="isActive()"
          routerLinkActive="open"
          appNavDropdown>
        <app-sidebar-nav-dropdown [link]='item'></app-sidebar-nav-dropdown>
      </li>
    </ng-template>
    `
})
export class AppSidebarNavItemComponent {
  @Input() item: any;

  public hasClass() {
    return this.item.class ? true : false
  }

  public isDropdown() {
    return this.item.children ? true : false
  }

  public thisUrl() {
    return this.item.url
  }

  public isActive() {
    return this.router.isActive(this.thisUrl(), false)
  }

  constructor( private router: Router )  { }

}

@Component({
  selector: 'app-sidebar-nav-link',
  template: `
    <a appMobileSidebarToggler *ngIf="isEvent(); else linkNav"
      [ngClass]="hasVariant() ? 'nav-link nav-link-' + link.variant : 'nav-link'"
      routerLinkActive="active"
      (click)="feedbackModal(feedback)" >
      <i *ngIf="isIcon()" class="{{ link.icon }}"></i>
      {{ link.name }}
      <span *ngIf="isBadge()" [ngClass]="'badge badge-' + link.badge.variant">{{ link.badge.text }}</span>
    </a>
    <ng-template #linkNav>
    <!-- add this after ngIf line below else external -->
    <a appMobileSidebarToggler *ngIf="!isExternalLink(); "
      [ngClass]="hasVariant() ? 'nav-link nav-link-' + link.variant : 'nav-link'"
      routerLinkActive="active"
      [routerLink]="[link.url]">
      <i *ngIf="isIcon()" class="{{ link.icon }}"></i>
      {{ link.name }}
      <span *ngIf="isBadge()" [ngClass]="'badge badge-' + link.badge.variant">{{ link.badge.text }}</span>
    </a>
    </ng-template>
    <ng-template #external>
      <a appMobileSidebarToggler *ngIf="!isExternalLink()"
       [ngClass]="hasVariant() ? 'nav-link nav-link-' + link.variant : 'nav-link'" href="{{link.url}}">
        <i *ngIf="isIcon()" class="{{ link.icon }}"></i>
        {{ link.name }}
        <span *ngIf="isBadge()" [ngClass]="'badge badge-' + link.badge.variant">{{ link.badge.text }}</span>
      </a>
    </ng-template>

    <ng-template #feedback>
    <div class="modal-body">
    <div class="container">
      <h4 class="mt-3">Give feedback</h4>
      <div class="mt-2">I'm giving feedback about <span class="text-danger">*</span></div> 
      <select class="form-control mt-2" id="ccmonth" [(ngModel)]="feedbackSelected">
        <option value="0" disabled selected>Select your option</option>
        <option value="bugs">Bugs</option>
        <option value="performance">Performance</option>
        <option value="lookandfeel">Look and feel</option>
        <option value="other">Other</option>
      </select>
      <div class="mt-3" [ngSwitch]="feedbackSelected">
        <span *ngSwitchCase="'bugs'">Tell us more about any problems you found
          <span class="text-danger">*</span>
        </span>
        <span *ngSwitchCase="'performance'">Tell us what you think about performance
          <span class="text-danger">*</span>
        </span>
        <span *ngSwitchCase="'lookandfeel'">Tell us what you think
          <span class="text-danger">*</span>
        </span>
        <span *ngSwitchCase="'other'">Let us know about your experience
          <span class="text-danger">*</span>
        </span>
      </div>
      <div class="form-group">
        <textarea class="form-control mt-2 feedback-message" [(ngModel)]="message" [hidden]="feedbackSelected == '0'" id="textarea-input"  placeholder="enter feedback here..." spellcheck="true" style="min-height: 12rem;"></textarea>
      </div>
        
      <div class="form-check checkbox mt-2" [hidden]="feedbackSelected == '0'">
        <input class="form-check-input pl-0" type="checkbox" checked  [(ngModel)]="canContactBack" name="cancontact" id="cancontact">
        <label class="form-check-label" for="cancontact">Maker can contact me about my feed back.</label>
      </div>
    </div>
    
  </div>
  <div class="modal-footer border-0 pt-0">
    <button type="button" class="btn btn-sm btn-primary" [disabled]="message == null || message.length < 1" (click)="submitFeedback()">Submit</button>
    <button type="button" class="btn btn-sm" (click)="modalRef.hide()">Cancel</button>
  </div>

    </ng-template>
  `
})
export class AppSidebarNavLinkComponent implements OnInit {
  @Input() link: any;
  // form
  feedbackSelected = '0';
  message = '';
  canContactBack = true;

  modalRef: BsModalRef;
  constructor(
    private modalService: BsModalService,
    private auth: AuthService,
    private toastr:ToastrService,
    private email: EmailService
  ) {
  }
  
  ngOnInit(){ }
 
  feedbackModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  submitFeedback(){
    let feedbackData = new  HttpParams()
    .set('category', this.feedbackSelected)
    .set('from', this.auth.getAuthUser().email)
    .set('canContactBack',`${this.canContactBack}`)
    .set('message', this.message);


    
    this.email.postFeedback(feedbackData, this.auth.getAuthUser()).subscribe(
      res => {
        this.toastr.success('Feedback sent successfully', 'Success');
      },
      err => {
        this.toastr.error('Error sending feedback', 'Error');
      },
      () => this.modalRef.hide()
    );
    // if(this.canContactBack) alert('Thank for sending feedback, we will get back to you as soon as posible.');
    // else alert('Thank for sending feedback.');

  }


  public hasVariant() {
    return this.link.variant ? true : false
  }

  public isBadge() {
    return this.link.badge ? true : false
  }

  public isEvent() {
    return this.link.event ? true : false
  }

  public isExternalLink() {
    return this.link.url.substring(0, 4) === 'http' ? true : false
  }

  public isIcon() {
    return this.link.icon ? true : false
  }
 
}

@Component({
  selector: 'app-sidebar-nav-dropdown',
  template: `
    <a class="nav-link nav-dropdown-toggle" appNavDropdownToggle>
      <i *ngIf="isIcon()" class="{{ link.icon }}"></i>
      {{ link.name }}
      <span *ngIf="isBadge()" [ngClass]="'badge badge-' + link.badge.variant">{{ link.badge.text }}</span>
    </a>
    <ul class="nav-dropdown-items">
      <ng-template ngFor let-child [ngForOf]="link.children">
        <app-sidebar-nav-item [item]='child'></app-sidebar-nav-item>
      </ng-template>
    </ul>
  `
})
export class AppSidebarNavDropdownComponent {
  @Input() link: any;

  public isBadge() {
    return this.link.badge ? true : false
  }

  public isIcon() {
    return this.link.icon ? true : false
  }

  constructor() { }
}

@Component({
  selector: 'app-sidebar-nav-title',
  template: ''
})
export class AppSidebarNavTitleComponent implements OnInit {
  @Input() title: any;
  user;
  constructor(private el: ElementRef, private renderer: Renderer2, private _as: AuthService) { }

  ngOnInit() {
    this.user = this._as.getAuthUser();
    const nativeElement: HTMLElement = this.el.nativeElement;
    const li = this.renderer.createElement('li');
    let name; 
    if(this.title.userLogin)
      name = this.renderer.createText(this.user.email);
    else
      name = this.renderer.createText(this.title.name);
    
    this.renderer.addClass(li, 'nav-title');

    if ( this.title.class ) {
      const classes = this.title.class;
      this.renderer.addClass(li, classes);
    }

    if ( this.title.wrapper ) {
      const wrapper = this.renderer.createElement(this.title.wrapper.element);

      this.renderer.appendChild(wrapper, name);
      this.renderer.appendChild(li, wrapper);
    } else {
      this.renderer.appendChild(li, name);
    }
    this.renderer.appendChild(nativeElement, li)
  }
}

export const APP_SIDEBAR_NAV = [
  AppSidebarNavComponent,
  AppSidebarNavDropdownComponent,
  AppSidebarNavItemComponent,
  AppSidebarNavLinkComponent,
  AppSidebarNavTitleComponent
];
