import { URL_BANNER_GET_GLOBAL } from './../variables/app.variables';


import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError, publish } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { IAppPreferenceZip, IBoostSearch, IChart, ICompanySelected, IDateRange, IRawSearch, IRelateKeyword, ISiteDomainF, ITotalSearch, IBannerAd, IAdHistory, IUser, IBannerAPIResponse } from 'app/interfaces';
import { URL_GET_PREFERENCES, URL_RAW_SEARCH, URL_SEARCH_TERM, URL_SET_PREFERENCES, URL_TOTAL_SEARCH, URL_BANNER_POST, URL_BANNER_GET, URL_BANNER_GETALL, URL_BANNER_DELETE, APP_DATE_PATTERN, URL_IMAGE_BANNER_DELETE, URL_BANNER_PUBLISH, URL_BANNER_UNPUBLISH } from 'app/variables';
import { isDefined as isDefine, saveToLocalStorage, getLocalStorage } from 'app/utilities';
import * as moment from 'moment';
import { Injectable } from '@angular/core';








@Injectable()
export class DataService {


	// private _relateKeywordUrl = '../../assets/api/related-keyword.json';
	// private _boostSearcActivehUrl = '../../assets/api/boost-search-active.json';
	// private _boostSearcInActivehUrl = '../../assets/api/boost-search-inactive.json';
	// private _availableSiteDataUrl = '../../assets/api/available-site.json';


	constructor(
		private http: HttpClient,
		private router: Router
	) {
		// console.log('constructor DataService');
		/*
		Initialize app's data here
		*/

	}


	/* 
			Application data
	*/



	/* 
			Static app data
	*/


	/*
			Real time whole app sharing data
	*/
	// General data holder
	public testObs = new Observable(observer => {
		observer.next(1);
		observer.error('error message');
		observer.next(3);
		observer.complete();
	}).pipe(
		publish(),
	);

	// list holder
	private _dataListSource = new BehaviorSubject<Array<any>>([]);
	dataList = this._dataListSource.asObservable();
	setDataList(dataList: any) { this._dataListSource.next(dataList); }

	private _dataSecondListSource = new BehaviorSubject<Array<any>>([]);
	dataSecondList = this._dataSecondListSource.asObservable();
	setSecondDataList(dataList: any) { this._dataSecondListSource.next(dataList); }


	private _domainGroupTitleSource = new BehaviorSubject<any>({});
	domainGroupTitle$ = this._domainGroupTitleSource.asObservable();
	setDomainGroupTitle(data: any) { this._domainGroupTitleSource.next(data); }


	private _initDateRange: IDateRange = { title: 'loading..', selected: null, range: 'loading..', datefrom: new Date(), dateto: new Date() };
	private appPreferencesSource = new BehaviorSubject<IAppPreferenceZip>({ companySelect: { selectedCompanyId: 0, selectedDomains: [0] }, dateRange: this._initDateRange });
	appPreferences = this.appPreferencesSource.asObservable();
	async setAppPreferences(data: IAppPreferenceZip, auth: IUser) {
		if (auth == undefined) return;
		await this.setApiAppPreferences(data, auth)
			.then(res => {
				if (res.result == 1)
					this.appPreferencesSource.next(data);
			})
			.catch(err => this._catchPromiseError(err));
	}

	private _CompanySelectionSource = new BehaviorSubject<ICompanySelected>({ selectedCompanyId: 0, selectedDomains: [] });
	companySelection$ = this._CompanySelectionSource.asObservable();
	setCompanySelect(com: ICompanySelected, auth: IUser) {
		if (this._dataDateRangeSource.value.selected != null && this._CompanySelectionSource.value.selectedCompanyId != 0) {
			this.setApiAppPreferences({ companySelect: com, dateRange: this._dataDateRangeSource.value }, auth)
				.then(res => {
					// if(res.result == 1)
				});
		}
		this._CompanySelectionSource.next(com);
	}

	private _dataDateRangeSource = new BehaviorSubject<IDateRange>({ title: 'loading..', selected: null, range: 'loading..', datefrom: new Date(), dateto: new Date() });
	dataDateRange$ = this._dataDateRangeSource.asObservable();
	async setDataDateRange(newData: IDateRange, auth: IUser) {
		// this.appPreferencesSource.value.selectedCompanyId
		if (newData.selected != null && this._CompanySelectionSource.value.selectedCompanyId != 0) {
			// console.log('set date range');
			await this.setApiAppPreferences({ companySelect: this._CompanySelectionSource.value, dateRange: newData }, auth);
		}
		this._dataDateRangeSource.next(newData);
		// this._dataDateRangeSource.complete();
	}

	/* Banner Ads API agent methods */

	private _bannerAdsSource = new BehaviorSubject<Array<IBannerAd>>([]);
	bannerAds$ = this._bannerAdsSource.asObservable();

	public updateLocalBannerAds(ads: Array<IBannerAd>) {
		// console.log('updateLocalBannerAds', ads);
		this._bannerAdsSource.next(ads);
	}

	public addLocalBannerAd(newAd: IBannerAd) {
		let temp = this._bannerAdsSource.value;
		temp.push(newAd)
		this._bannerAdsSource.next(temp);
	}

	public deleteLocalBannerAd(id: number) {
		let newData = this._bannerAdsSource.value.filter(ad => ad.id !== id);	
		this._bannerAdsSource.next(newData);
	}

	public deleteAllLocalBannerAd() {
		this._bannerAdsSource.next([]);
	}

	public apiUpdateBannerAds(ads: Array<IBannerAd>) {
		this._bannerAdsSource.next(ads);
	}

	public apiNewBannerAd(ad: IBannerAd, auth: IUser) {
		const url = URL_BANNER_POST;
		const httpOptions = {
			headers: new HttpHeaders({
				'enctype': 'multipart/form-data',
				'Authorization': 'Bearer ' + auth.token,
			})
		};


		let formData = new FormData();
		formData.append('username', auth.email);
		formData.append('password', auth.password);
		formData.append('id', ad.id ? ad.id.toString() : null);
		formData.append('name', ad.name);
		formData.append('mode', ad.mode ? ad.mode.toString() : '');
		formData.append('description', ad.description);
		if (ad.bannerDomains[0].domainId != undefined)
			ad.bannerDomains.forEach(ad => formData.append('domains[]', ad.domainId));
		else
			ad.bannerDomains.forEach(id => formData.append('domains[]', id));
		formData.append('keywords', ad.additionalKeywords ? ad.additionalKeywords.toString() : null);
		formData.append('datefrom', moment(ad.datefrom).format('YYYY-MM-DD') || null);
		formData.append('dateto', moment(ad.dateto).format(APP_DATE_PATTERN) || null);

		if (ad.bannerImageDesktop != undefined) {
			formData.append('banner_image_desktop', ad.bannerImageDesktop[0], ad.bannerImageDesktop[0].name);
		}
		formData.append('banner_url_desktop', ad.adPageUrl);
		if (ad.bannerImageMobile) {
			formData.append('banner_image_mobile', ad.bannerImageMobile[0], ad.bannerImageMobile[0].name);
		}
		formData.append('banner_url_mobile', ad.adPageMobileUrl);

		return this.http.post(url, formData, httpOptions).toPromise();
	}

	public apiDeleteBannerAd(id: number, auth: IUser) {
		const url = `${URL_BANNER_DELETE}/id/${id}`;
		const headers = new HttpHeaders()
			.set('Authorization', 'Bearer ' + auth.token);

		return this.http.get(url, { headers: headers }).toPromise();
	}

	public apiGetBannerAd(id: number, auth: IUser): Promise<IBannerAd> {
		const url = `${URL_BANNER_GET}/id/${id}`;

		const headers = new HttpHeaders()
			.set('Authorization', 'Bearer ' + auth.token);

		return this.http.get(url, { headers: headers })
			.pipe(
				map((res: any) => {
					return this._bannerMapping(res.result);
				})
			)
			.toPromise();
	}

	private _bannerMapping(res: IBannerAPIResponse): IBannerAd {
		let serverResNoDateFormate = '0000-00-00 00:00:00';
		let data = res;
		let map: IBannerAd;

		// let datefrom = new Date(data.datefrom);
		// let dateto = new Date(data.dateto);
		// let updateon = new Date(serverResNoDateFormate);
		// let createon = new Date(serverResNoDateFormate);

		map = {
			id: data.id,
			name: data.name,
			bannerDomains: data.banner_domains,
			description: data.description,
			adPageUrl: data.desktopurl,
			datefrom: moment(data.datefrom, APP_DATE_PATTERN).toDate(),
			dateto: moment(data.dateto, APP_DATE_PATTERN).toDate(),
			updatedOn: new Date(serverResNoDateFormate),
			createdOn: new Date(serverResNoDateFormate),
			adPageMobileUrl: data.mobileurl,
			bannerImageDesktopUrl: data.banner_base_url + data.desktopimage,
			bannerImageMobileUrl: data.banner_base_url + data.mobileimage,
			selected: false,
			additionalKeywords: data.keywords,
			clickCount: data.clicks ? data.clicks : 0,
			viewCount: data.impressions ? data.impressions : 0,
			mode: data.mode
		}

		if (data.desktopimage == '')
			map.bannerImageDesktopUrl = '/assets/img/placeholder.png';

		if (data.mobileimage == '')
			map.bannerImageMobileUrl = '/assets/img/placeholder.png';

		if (data.datefrom == serverResNoDateFormate || data.dateto == serverResNoDateFormate) {
			map.selected = false;
		} else {
			map.selected = true;
		}
		return map;
	}

	public apiGetAllBanners(auth: IUser): Promise<Array<number>> {
		const url = `${URL_BANNER_GETALL}`;
		const headers = new HttpHeaders()
			.set('Authorization', 'Bearer ' + auth.token);

		return this.http.get(url, { headers: headers })
			.pipe(
				map((res: { result: { banners: Array<number> } }) => {
					return res.result.banners;
				}))
			.toPromise();
	}


	public apiGetPublishedBanner(auth: IUser): Promise<IBannerAd[]> {

		const headers = new HttpHeaders()
			.set('Authorization', 'Bearer ' + auth.token);


		const url = `${URL_BANNER_PUBLISH}`;
		return this.http.get(url, { headers: headers }).pipe(map((res: any) => {
			let banners: Array<IBannerAd> = [];

			if (res.result.length > 0) {
				res.result.forEach(banner => {
					banners.push(this._bannerMapping(banner))
				});
			}
			return banners;
		}))
			.toPromise();
	}

	public apiGetUnpublishedBanner(auth: IUser): Promise<IBannerAd[]> {
		// console.log('apiGetUnpublishedBanner');

		const url = `${URL_BANNER_UNPUBLISH}`;

		const headers = new HttpHeaders()
			.set('Authorization', 'Bearer ' + auth.token);

		return this.http.get(url, { headers: headers }).pipe(map((res: any) => {
			let banners: Array<IBannerAd> = [];

			if (res.result.length > 0) {
				res.result.forEach(banner => {
					banners.push(this._bannerMapping(banner))
				});
			}
			return banners;
		}))
			.toPromise();
	}


	public apiGetGlobalBanners(auth: IUser): Promise<IBannerAd[]> {
		const url = `${URL_BANNER_GET_GLOBAL}`;

		const headers = new HttpHeaders()
			.set('Authorization', 'Bearer ' + auth.token);

		return this.http.get(url, { headers: headers }).pipe(
			map((res: any) => {
				let banners: Array<IBannerAd> = [];
				if (res.result.length > 0) {
					res.result.forEach(banner => {
						banners.push(this._bannerMapping(banner))
					});
				}
				return banners;
			})
		).toPromise();

	}

	/**
	 * Remove banner image in the back-end
	 *
	 * @param id - banner ad id
	 * @param type - image type 'desketopimage' or 'mobileimage'
	 * @returns Promise
	 */
	public apiDeleteImageBannerAd({ id, type, auth }: { id: number; type: 'desktopimage' | 'mobileimage'; auth: IUser; }): Promise<IDeleteBannerImageResult> {

		const url = `${URL_IMAGE_BANNER_DELETE}/banner_id/${id}/imagetype/${type}`;
		const headers = new HttpHeaders()
			.set('Authorization', 'Bearer ' + auth.token);

		return this.http.get(url, { headers: headers })
			.pipe(
				map((res: IDeleteBannerImageResult) => {
					return res;
				})
			).toPromise();
	}




	dummyServerData = [
		// {
		//     id: 1,
		//     status: 'changed',
		//     dateTime: new Date(2018, 9, 27, 7, 19),
		//     mode: 'static',
		//     adsArchive: [123,124]
		// },
		// {
		//     id: 2,
		//     status: 'current',
		//     dateTime: new Date(2018, 9, 12, 14, 38),
		//     mode: 'static',
		//     adsArchive: [3,56]
		// }
	];
	private _adsHistorySource = new BehaviorSubject<Array<IAdHistory>>([]);
	adsHistory$ = this._adsHistorySource.asObservable();
	getApiBannerAdHistory() {
		// load from API
		// determine by returning status / need to make sure 
		//  if it no data or network error
		// eg. return code = 0, mean no data in database
		// that mean we can init first data

		// after load data form Api

		this._adsHistorySource.next(this.dummyServerData);
	}

	setApiBannerAdHistory(adsHistory: Array<IAdHistory>) {
		this._adsHistorySource.next(adsHistory);
	}

	pushApiBannerAdHistory(adHistory: IAdHistory) {
		this._adsHistorySource.value.forEach(ad => {
			ad.status = 'changed';
		});
		this._adsHistorySource.value.push(adHistory);
		console.log(this._adsHistorySource.value);
	}

	getBannerAdSettings(): any {
		return getLocalStorage('bannerAdSettings');
	}
	setBannerAdSettings(object: any) {
		return saveToLocalStorage('bannerAdSettings', object);
		// this._bannerAdSettingsSoruce.next(object);
	}

	fetchUrlMetatags(url: string): Promise<any> {
		return this.http.get('https://api.urlmeta.org/?url=' + url).toPromise();
	}

	/* App Preferences */

	getApiAppPreferences(auth: IUser): Promise<IAppPreferenceZip> {
		//call widget api
		// const url = `${URL_GET_PREFERENCES}/username/${encodeURIComponent(auth.email)}/password/${encodeURIComponent(auth.password)}`;
		// alert(url);

		// set auth bearer token
		const headers = new HttpHeaders()
			.set('Authorization', 'Bearer ' + auth.token);

		return this.http.get(URL_GET_PREFERENCES, { headers: headers })
			.pipe(
				map(
					(data: { result: string }) => {
						// console.log(data.result);

						if (data.result != "") {
							let unwrap = window.atob(data.result);
							return JSON.parse(unwrap);
						}
						return data.result;
					}
				)
			)
			.toPromise();
	}


	setApiAppPreferences(data: IAppPreferenceZip, auth: IUser): Promise<{ result: number }> {

		const dataEncoded = encodeURIComponent(btoa(JSON.stringify(data)));
		let url = `${URL_SET_PREFERENCES}${dataEncoded}`;
		const headers = new HttpHeaders()
			.set('Authorization', 'Bearer ' + auth.token);


		return this.http.get(url, { headers: headers })
			.pipe(
				map((data: { result: number }) => { return data; })
			)
			.toPromise();
	}



	/* 
			Search Stats
	*/

	// availble parameters for /search-stats
	// domainid|1|2|3
	// /limit/:number
	// /datefrom/yyyy-mm-dd hh:mm:ss   option for date: yesterday, today, lastweek ... try it
	// /dateto/yyyy-mm-dd hh:mm:ss
	count = 1;
	getTopSearch(auth: IUser, domainList: Array<ISiteDomainF>, dateFrom: Date, dateTo: Date, widgetId?: number, limit?: string): Observable<any> {
		let url = `${URL_SEARCH_TERM}/domainid/`;
		//dateFrom == undefined ? dateFrom = '' : dateFrom;
		//dateTo == undefined ? dateTo = 'today' : dateTo;
		limit == undefined ? limit = '10' : limit; // default limit = 10
		let domainIdForUrl = this._convertDomainIdToUrlFormat(domainList);
		url = url + encodeURIComponent(domainIdForUrl) +
			'/limit/' + limit +
			'/datefrom/' + encodeURIComponent(moment(dateFrom).format('YYYY-MM-DD')) +
			'/dateto/' + encodeURIComponent(moment(dateTo).format(APP_DATE_PATTERN));
		// url = `${url}/${this._attachAuthUrl(auth)}`;

		const headers = new HttpHeaders()
			.set('Authorization', 'Bearer ' + auth.token);

		return this.http.get(url, { headers: headers })
			.pipe(
				map((data: any) => {
					this.count = 1;
					if (isDefine(data)) {
						let tempChartData: IChart = { data: [], datasets: [], labels: [], chartType: null, options: null, colors: null, legend: null };
						let rawDataArray = [];
						for (let i in data) {
							if (i != 'debug') {
								tempChartData.data.push(data[i].count);
								tempChartData.labels.push(data[i].searchterm);
								rawDataArray.push({ searchterm: data[i].searchterm, data: data[i].count });
							}
						}
						tempChartData.datasets.push({ data: tempChartData.data, label: '' });
						if (data.debug != undefined) delete data.debug
						// convert rawdata object to an array

						let dataForChart = {
							rawData: rawDataArray,
							chartData: tempChartData
						};
						if (widgetId) return [dataForChart, widgetId];
						else return dataForChart;
					}
				}),
				catchError(err => this._catchAPIError(err))
			);

	}

	public getPureTopSearch(
		auth: IUser,
		domainList: Array<ISiteDomainF>,
		dateFrom: Date,
		dateTo: Date,
		widgetId?: number,
		limit?: string
	): Promise<any> {
		let url = `${URL_SEARCH_TERM}/domainid/`;
		//dateFrom == undefined ? moment('') : dateFrom;
		//dateTo == undefined ? dateTo = 'today' : dateTo;
		limit == undefined ? limit = '10' : limit; // default limit = 10
		let domainIdForUrl = this._convertDomainIdToUrlFormat(domainList);
		url = url + encodeURIComponent(domainIdForUrl) + '/limit/' + limit + '/datefrom/' + encodeURIComponent(moment(dateFrom).format('YYYY-MM-DD')) + '/dateto/' + encodeURIComponent(moment(dateTo).format(APP_DATE_PATTERN));
		// url = `${url}/${this._attachAuthUrl(auth)}`;

		const headers = new HttpHeaders()
			.set('Authorization', 'Bearer ' + auth.token);

		return this.http.get(url, { headers: headers }).toPromise();
	}

	getRecentSearch(auth: IUser, domainList: Array<ISiteDomainF>, dateFrom: Date, dateTo: Date, limit?: string, widgetId?: number): Observable<any> {
		limit == null ? limit = '50' : limit;
		let requestUrl =
			`${URL_RAW_SEARCH}/domainid/${this._convertDomainIdToUrlFormat(domainList)}
		/datefrom/${moment(dateFrom).format('YYYY-MM-DD')}
		/dateto/${moment(dateTo).format(APP_DATE_PATTERN)}
		/limit/${limit}`;

		const headers = new HttpHeaders()
			.set('Authorization', 'Bearer ' + auth.token);

		return this.http.get(requestUrl, { headers: headers })
			.pipe(
				map((data: Array<IRawSearch>) => {
					// for (let i of data) {
					//     i["timestamp"] = i["timestamp"];
					//     delete i["timestamp"];
					// }
					// if(widgetId) return [data,widgetId];
					return data;
				}),
				map((data: Array<IRawSearch>) => {
					// we can't plot chart with Date data , so we won't have chart data for recent search. we will just add null data for it.
					let tempChartData: IChart = { data: [], datasets: [], labels: [], chartType: null, options: null, colors: null, legend: null };
					let dataForChart = { rawData: data, chartData: tempChartData };
					if (widgetId) return [dataForChart, widgetId];
					else return dataForChart;
				}),
				catchError(err => this._catchAPIError(err))
			);

	}


	getTotalSearch(auth: IUser, domainList: Array<ISiteDomainF> | number, groupBy: string, dateFrom: Date, dateTo: Date, limit?: string) {
		limit ? limit : (limit = "10000000");
		let url = `${URL_TOTAL_SEARCH}/domainid/${this._convertDomainIdToUrlFormat(domainList)}/groupby/${groupBy}/datefrom/${moment(dateFrom).format('YYYY-MM-DD')}/dateto/${moment(dateTo).format(APP_DATE_PATTERN)}/limit/${limit}`;

		const headers = new HttpHeaders()
			.set('Authorization', `Bearer ${auth.token}`);


		return this.http.get(url, { headers: headers })
			.pipe(
				map((data: ITotalSearch) => {
					return data;
				})
			);
	}


	private _convertDomainIdToUrlFormat(domainList: Array<ISiteDomainF> | number): string {
		let domainIdForUrl = "";
		if (typeof domainList === 'object') {
			for (let i in domainList) {
				if (domainList[i].selected == true) {
					domainIdForUrl += "" + domainList[i].domainId;
					if (parseInt(i) != domainList.length - 1) domainIdForUrl += "|";
				}
			}
			return encodeURIComponent(domainIdForUrl);
		} else if (typeof domainList == 'string') {
			// if domainList is just a number, we just return it back as a string
			return domainList;
		}
	}

	private _catchAPIError(err) {

		if (this.count == 1 && err.status == 0) {
			//   let c = confirm("Session expired, please refresh the application. To refresh now press OK");
			//   if (c == true) location.reload();
			//   else {
			//      alert("Since session expired, and you avoid to refresh. For safety reason we will log you out.");
			//     }
			// alert('Session expired or account has been loged out.')
			this._navigateToLogin();
			this.count++;
		} else if (this.count > 3) {
		}
		return throwError(err);

	}

	private _catchPromiseError(error: any) {
		console.log(error);
	}

	private _attachAuthUrl(auth: IUser): string {
		return `username/${encodeURIComponent(auth.email)}/password/${encodeURIComponent(auth.password)}`;
	}




	/*
		Boost Search
	*/
	// active
	getBoostSearch(): Observable<any> {
		return null // this.http.get(this._boostSearcActivehUrl);
	}
	postBoostSearch(data: IBoostSearch) { console.log('Post New Boost Page data to server'); }
	pushBoostSearch(data: IBoostSearch) { console.log('Send Data to API'); }
	deleteBoostSearch(id: number) { console.log('Delete Boost Search NO : ' + id); }

	// inactive
	getBoostSearchInActive(): Observable<any> {
		return null // this.http.get(this._boostSearcInActivehUrl);
	}
	postBoostSearchInActive() { }
	pushBoostSearchInActive() { }
	deleteBoostSearchInActive() { }


	/*
		Related Keyword
	*/

	getRelateKeywords(): Observable<any> {
		return null //this.http.get(this._relateKeywordUrl);
	}

	patchRelateKeyword(editedItem: IRelateKeyword) {
		// this.http.patch(this._relateKeywordUrl, editedItem);
	}

	deleteRelateKeyword(id: number) {
		// this.http.delete(this._relateKeywordUrl + '/' + id);
	}

	postRelateKeyword(data: IRelateKeyword): Observable<any> {
		return null; // this.http.post(this._relateKeywordUrl, data);
	}



	private _navigateToLogin() {
		this.router.navigateByUrl('/account/login');
	}
}


interface IDeleteBannerImageResult {
	result: { database_update_success: number, remove_image_success: number }
}