import { Component, OnInit } from '@angular/core';
import { ICompanyF, ICompanySelected, ILoginData } from 'app/interfaces';
import { AppStateService, DataService, AuthService } from 'app/services';
import { getLocalStorage, mergeSelection } from 'app/utilities';

@Component({
    selector: 'app-panel',
    templateUrl: './app-panel.component.html',
    styleUrls:['./app-panel.component.scss']
})
export class AppPanelComponent implements OnInit {

    addSitePanel = false;
    onAddSite = true;
    selectedCompany: ICompanyF = { companyId: null, companyName: 'loading...', domains: null, selected: null, default: null };
    selectedGroupTitle = 'All Domains Data';
    availableCompanies: Array<ICompanyF>;
    appSettingDataHolder: ILoginData;

    constructor(
        private dataService: DataService,
        private appState: AppStateService,
        private _as: AuthService
    ) { }

    ngOnInit() {   
        // this.addSitePanel = true;
        let rawCom = getLocalStorage('rawCompany');
        // get app preference
        this.dataService.companySelection$.subscribe(
            res => {
                // mearge to IAppSetting
                this.availableCompanies = mergeSelection(rawCom, res);
                this.findSelectedCompany();
                this.determineGroupTitle();
                // this.updateSelectionChange();
            }
        );
    }


    /*
        Click event action
    */

    onSelectDomains(index: number) {

        let tempBool = !this.selectedCompany.domains[index].selected;
        this.selectedCompany.domains[index].selected = tempBool;
        setTimeout(() => {
            if (this.noDomainSeleted()) { this.checkDefaultSubSite(); }
            this.determineGroupTitle();
            this.updateSelectionChange();
        });
    }

    onSelectCompany(index: number) {
        this.appState.showSpinner();
        for (let i in this.availableCompanies) {
            this.availableCompanies[i].selected = false;
        }
        this.availableCompanies[index].selected = true;
        this.selectedCompany = this.availableCompanies[index];
        this.determineGroupTitle();
        this.updateSelectionChange();
        this.appState.hideSpinner();
    }

    // disable on UI for now
    changeDefaultCompany(index: number) {
        for (let i in this.availableCompanies) {
            this.availableCompanies[i].default = false;
        }
        this.availableCompanies[index].default = true;
        this.determineGroupTitle();
        this.updateSelectionChange();
    }






    /*
        Background logic
    */


    private findSelectedCompany() {
        try {
            this.selectedCompany = this.availableCompanies.find(x => x.selected == true);
        } catch (e) {

        }
    }

    private determineGroupTitle() {
        // find out if select all or not
        // if (this.isAllDomainsSelected()) { this.selectedGroupTitle = 'All Domains Data'; }
        // else {
            let selectedCount = 0;
            for (let i in this.selectedCompany.domains) {
                this.selectedCompany.domains[i].selected === true ? selectedCount++ : null;
            }

            if (selectedCount > 1) {
                // multiple site
                this.selectedGroupTitle =  `${selectedCount} Sites Data `;
            } else {
                // single site

                // same time looking for too long name (15+char)
                // and trim it to 11char + ..
                this.selectedGroupTitle = this.selectedCompany.domains.find(x => x.selected == true).domainName;
                if (this.selectedGroupTitle.length <= 9) {
                    this.selectedGroupTitle = this.selectedGroupTitle + ' Data';
                }
                if (this.selectedGroupTitle.length >= 15) {
                    // this.selectedGroupTitle = this.selectedGroupTitle.slice(0, 12) + '...';
                }

            }
        // }

        // Update data observable
        this.dataService.setDomainGroupTitle(this.selectedGroupTitle);

    }

    private checkDefaultSubSite() {
        const index = this.selectedCompany.domains.findIndex(x => x.default === true);
        this.selectedCompany.domains[index].selected = true;
    }

    private isAllDomainsSelected(): boolean {
        if (this.selectedCompany.domains.length > 1) {
            let slt = this.selectedCompany.domains.every(x => x.selected == true);
            if (slt == false) return false;
            else return true;
        } else {
            return false;
        }
    }

    private noDomainSeleted(): boolean {
        // make sure no sub site are unselected 
        return this.selectedCompany.domains.every(x => x.selected == false);
    }

    private updateSelectionChange() {
        // merge selected company to availableCompany
        let index = this.availableCompanies.findIndex(x => x.companyId == this.selectedCompany.companyId);
        this.availableCompanies[index] = this.selectedCompany;
        let tempDomainId = [];
        this.availableCompanies.forEach( (com, i) => {
            this.availableCompanies[i].domains.forEach( domain => {
                if(domain.selected) tempDomainId.push(domain.domainId);
            });
        });
        let temp: ICompanySelected = {selectedCompanyId:this.availableCompanies[index].companyId, selectedDomains:tempDomainId }
        this.dataService.setCompanySelect(temp, this._as.getAuthUser());
        // this.appSettingDataHolder.companies = this.availableCompanies;
        // setTimeout(() => {
        //     this.dataService.setUserLoginData(this.appSettingDataHolder);
        // }, 10);
    }




    /*
        UI
    */

    showPanel() {
        this.addSitePanel = true;
    }

    mouseEnter(event) {
        // this.addSitePanel = true;
    }

    mouseLeave(event) {
        // this.addSitePanel = false;
    }

    printLimitLengthString(str: string, maxLength: number):string{ 
        
        if(str.length < maxLength) return str;
        
        return str.slice(0, maxLength) + '...';
    }

}